<template>
  <a-spin :spinning="initLoading">
    <a-card v-if="!initLoading" title="编辑欣赏歌曲">
      <template #extra><a @click="handleBack">返回</a></template>
      <enjoy-song-form
        :actionType="FormActionType.EDIT"
        :current="current"
        @onSubmit="handleEdit"
      ></enjoy-song-form>
    </a-card>
  </a-spin>
</template>

<script>
import {
  editOfficialEnjoySong,
  getOfficialEnjoySongInfo,
  setOfficialEnjoySongTags,
  editPersonalEnjoySong,
  getPersonalEnjoySongInfo,
  setPersonalEnjoySongTags
} from '@/services/songs'
import { message } from 'ant-design-vue'
import { FormActionType } from './config'
import { onMounted, ref } from 'vue'
import EnjoySongForm from './EnjoySongForm.vue'
import { useRoute, useRouter } from 'vue-router'
export default {
  components: {
    EnjoySongForm
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const current = ref({})
    const initLoading = ref(true)

    const id = route.params.id

    const getSheetSongInfo = async (id) => {
      const getInfo = route.params.type === '1' ? getOfficialEnjoySongInfo : getPersonalEnjoySongInfo
      const data = await getInfo(id)
      current.value = {
        name: data.name,
        song_code: data.song_code,
        py: data.py,
        picture_url: data.picture_url,
        mp3_file_config: data.mp3_file_config,
        book_id: data.book_id,
        book_section_id: data.book_section_id,
        tags: data.tags.map(item => item.tag),
        bookName: data.book?.name,
        sectionName: data.section?.name
      }
      initLoading.value = false
    }
    onMounted(async () => {
      await getSheetSongInfo(id)
    })

    const loading = ref(false)

    const handleEdit = async (params, tags) => {
      try {
        delete params.book
        delete params.section
        delete params.tags
        delete params.bookName
        delete params.sectionName
        loading.value = true
        const editService = route.params.type === '1' ? editOfficialEnjoySong : editPersonalEnjoySong
        const setTagService = route.params.type === '1' ? setOfficialEnjoySongTags : setPersonalEnjoySongTags
        await editService(id, params)
        await setTagService(id, { tags })
        await editOfficialEnjoySong(id, params)
        await setOfficialEnjoySongTags(id, { tags })
        message.success('编辑成功')
        handleBack()
      } finally {
        loading.value = false
      }
    }

    const handleBack = () => {
      history.go(-1)
    }
    return {
      current,
      initLoading,
      FormActionType,
      handleBack,
      handleEdit
    }
  }

}
</script>

<style>
</style>
