<template>
  <div>
    <a-upload
      name="song_file"
      :file-list="null"
      :multiple="false"
      accept=".mp3"
      :before-upload="handleBeforeUpload"
    >
      <a-button :disabled="loading">
        <UploadOutlined />{{ currentFile === null ? "选择文件" : "重选文件" }}
      </a-button>
    </a-upload>
    <span style="margin-top: 10px">{{fileName}}</span>
    <a-progress v-if="uploadPrecent > 0" :percent="uploadPrecent" :status="uploadStatus"></a-progress>
    <div class="upload_opt_btn">
      <a-button type="primary" @click="handleUpload" :loading="loading">
        开始上传
      </a-button>
      <a-button
        class="clean_btn"
        type="primary"
        danger
        @click="handleCleanAll"
        :disabled="loading"
      >
        清空
      </a-button>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { uploadFileToOss } from '@/utils/oss'
import { message } from 'ant-design-vue'
import { UploadOutlined } from '@ant-design/icons-vue'
export default {
  emits: ['ok'],
  components: {
    UploadOutlined
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup (props, { emit }) {
    const valueComputed = computed(() => props.value)
    const currentFile = ref(null)

    const fileList = ref([])
    const fileName = ref('')

    const loading = ref(false)

    const uploadPrecent = ref(0)
    const uploadStatus = ref('normal')

    const handleBeforeUpload = file => {
      currentFile.value = file
      fileName.value = file.name
      uploadPrecent.value = 0
      uploadStatus.value = 'normal'
      return false
    }

    const onPrecentChange = (precent) => {
      uploadPrecent.value = precent
    }

    const handleUpload = async () => {
      if (currentFile.value) {
        try {
          loading.value = true
          uploadStatus.value = 'normal'
          const data = await uploadFileToOss(currentFile.value, true, onPrecentChange)
          const fileInfo = {
            song_name: data.name.substring(0, data.name.lastIndexOf('.')),
            mp3_file_url: data.url,
            mp3_file_md5: data.md5
          }
          uploadStatus.value = 'success'
          message.success('上传成功')
          loading.value = false
          emit('ok', fileInfo)
        } catch (error) {
          loading.value = false
          console.log(error)
          uploadStatus.value = 'exception'
          message.error('上传失败，请重试')
        }
      }
    }

    const handleCleanAll = () => {
      currentFile.value = null
      fileList.value = []
      fileName.value = ''
      uploadPrecent.value = 0
      uploadStatus.value = 'normal'
      loading.value = false
      const info = {
        song_name: '',
        mp3_file_url: undefined,
        mp3_file_md5: undefined
      }
      emit('ok', info)
    }

    return {
      loading,
      fileList,
      fileName,
      currentFile,
      valueComputed,
      uploadPrecent,
      uploadStatus,
      handleUpload,
      handleBeforeUpload,
      handleCleanAll
    }
  }
}
</script>

<style>
.upload_opt_btn {
  margin-top: 10px;
}

.clean_btn {
  margin-left: 10px;
}
</style>
