import { getOssUploadConfig } from '@/services/oss'
import store from '@/store'

export class SigleConfigFactory {
  private waitList: any[] = [];

  private static ins: SigleConfigFactory | null = null

  private loading = false

  private refreshDuration = 3600

  public static getInstance (): SigleConfigFactory {
    if (!SigleConfigFactory.ins) {
      SigleConfigFactory.ins = new SigleConfigFactory()
    }
    return SigleConfigFactory.ins
  }

  public isLoading () {
    return this.loading
  }

  // 通知等待的对象已经成功
  private notifySuccess () {
    this.waitList.forEach(item => {
      item.resolve()
    })
    this.waitList = []
  }

  // 通知等待的对象失败了
  private notifyError (error: any) {
    this.waitList.forEach(item => {
      item.reject(error)
    })
    this.waitList = []
  }

  // 获取oss配置
  private requetToGetOssConfig () {
    getOssUploadConfig().then((data: any) => {
      const config = data.upload_config
      config.refresh_time = new Date().getTime() + this.refreshDuration * 1000
      store.commit('setOssConfig', config)
      this.notifySuccess()
      this.loading = false
    }).catch(error => {
      this.notifyError(error)
      this.loading = false
    })
  }

  // 对外统一暴露的获取config的方法
  public getOssUploadConfig () {
    if (this.isLoading() === false) {
      this.requetToGetOssConfig()
      this.loading = true
    }
    return new Promise((resolve, reject) => {
      this.waitList.push({ resolve, reject })
    })
  }
}
