import { TreeItemType } from './config'

export const getTreeSelectInfo = (list: any[]) => {
  const treeList: any[] = []
  list.forEach((item: any) => {
    const treeNode:any = {
      value: item.id,
      title: item.name,
      key: item.id
    }
    if (item.type === TreeItemType.Chapter) {
      treeNode.children = getTreeSelectInfo(item.children || [])
    }
    treeList.push(treeNode)
  })
  return treeList
}
