// 表单类型
export const FormActionType = {
  CREATE: 'create',
  EDIT: 'edit'
}

export const TreeItemType = {
  Chapter: 0,
  Periods: 1
}

export const ColorList = [
  {
    value: 'yellow',
    label: '黄色',
    tag: '#ffc800'
  },
  {
    value: 'grown',
    label: '橙色',
    tag: '#ff720a'
  },
  {
    value: 'dark_blue',
    label: '深蓝色',
    tag: '#576fff'
  },
  {
    value: 'light_blue',
    label: '浅蓝色',
    tag: '#1ACDFC'
  },
  {
    value: 'green',
    label: '绿色',
    tag: '#7dcc17'
  },
  {
    value: 'purple',
    label: '紫色',
    tag: '#924cff'
  },
  {
    value: 'red',
    label: '玫红色',
    tag: '#fe558a'
  }
]

export const ColorMap = {
  yellow: '黄色',
  grown: '橙色',
  dark_blue: '深蓝色',
  light_blue: '浅蓝色',
  green: '绿色',
  purple: '紫色',
  red: '玫红色'
}
