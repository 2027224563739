import { getRequest, postRequest, deleteRequest } from '@/utils/http'

export async function getSubjectEnum () {
  return getRequest('/category/query-childcat-byrootcode', { code: 'subject' })
}

export async function getGradePeriodEnum () {
  return getRequest('/category/query-childcat-byrootcode', { code: 'grade_period' })
}

export async function getPressVersionEnum () {
  return getRequest('/category/query-childcat-byrootcode', { code: 'press_version' })
}

export async function createTags (id, params) {
  return postRequest(`/official/book/${id}/set-tags`, params)
}

export async function getOfficialList (params) {
  return getRequest('/official/books', params)
}

export async function getOfficialInfo (id) {
  return getRequest(`/official/${id}`)
}

export async function createOfficialTeachingMaterial (params) {
  return postRequest('/official/book/create', params)
}

export async function editOfficialTeachingMaterial (id, params) {
  return postRequest(`/official/book/${id}/edit`, params)
}

export async function deleteOfficialTeachingMaterial (id) {
  return deleteRequest(`/official/book/${id}/delete`)
}

export async function getOfficialBookSections (id) {
  return getRequest(`/official/book/${id}/sections`)
}

export async function getPersonalList (params) {
  return getRequest('/teacher/books', params)
}

export async function createPersonalTeachingMaterial (params) {
  return postRequest('/teacher/book/create', params)
}

export async function editPersonalTeachingMaterial (id, params) {
  return postRequest(`/teacher/book/${id}/edit`, params)
}

export async function createPersonalTags (id, params) {
  return postRequest(`/teacher/book/${id}/set-tags`, params)
}

export async function deletePersonalTeachingMaterial (id) {
  return deleteRequest(`/teacher/book/${id}/delete`)
}

export async function getPersonalBookSections (id) {
  return getRequest(`/teacher/book/${id}/sections`)
}

export const copyBook = async (id) => {
  return postRequest('/teacher/book/copy-from-official', { official_book_id: id })
}
