<template>
  <a-modal
    :title="isEdit ? '编辑配置' : '新增配置'"
    :visible="visibleComputed"
    :maskClosable="false"
    width="1000px"
    okText="确定"
    cancelText="取消"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <a-form
      v-model:model="current"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol"
    >
      <a-form-item label="MP3歌曲">
        <upload-song @ok="handleSelectSongOk"></upload-song>
      </a-form-item>
      <a-form-item label="歌曲名称" name="song_name">
        <a-input v-model:value="current.song_name"></a-input>
      </a-form-item>
      <a-form-item label="歌曲地址">
        <a-input
          v-model:value="current.mp3_file_url"
          :disabled="true"
        ></a-input>
      </a-form-item>
      <a-form-item label="歌曲md5">
        <a-input
          v-model:value="current.mp3_file_md5"
          :disabled="true"
        ></a-input>
      </a-form-item>
      <a-row
        size="small"
        v-for="(pItem, pIndex) in current.para_info"
        :key="pIndex"
      >
        <a-col :span="6" :offset="3">
          <a-form-item
            label="分段名"
            :labelCol="{ span: 8 }"
            :wrapperCol="{ span: 14 }"
            :rules="[{ required: true, message: '请输入分段名' }]"
          >
            <a-input
              v-model:value="pItem.name"
              style="width: 100%; margin-right: 10px"
            />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item
            :labelCol="{ span: 10 }"
            :wrapperCol="{ span: 12 }"
            label="分段起始点"
            :rules="[{ required: true, message: '请输入起始点' }]"
          >
            <a-input-number v-model:value="pItem.start_time" :step="0.1" :min="0"  /><span> 秒</span>
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item
            label="分段颜色"
            :labelCol="{ span: 10 }"
            :wrapperCol="{ span: 12 }"
          >
            <a-select v-model:value="pItem.color" :options="colorList">
              <template #option="{ label, tag }">
                <a-tag style="height: 10px" :color="tag"></a-tag>
                {{ label }}
              </template>
            </a-select>
          </a-form-item>
        </a-col>
        <MinusCircleOutlined
          @click="() => handleDelete(pItem)"
          class="delete-button"
        />
      </a-row>
      <a-form-item :wrapperCol="{ offset: 5, span: 17 }">
        <a-button style="width: 100%" @click="handleAddParaInfo"
          ><PlusOutlined />新增</a-button
        >
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { computed, ref, toRaw } from 'vue'
import UploadSong from './UploadSong.vue'
import { ColorList } from './config'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'

export default {
  components: {
    UploadSong,
    MinusCircleOutlined,
    PlusOutlined
  },
  emits: ['onOk', 'onEditOk', 'cancel'],
  props: {
    visible: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    isEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup (props, { emit }) {
    const visibleComputed = computed(() => props.visible)
    const isEditComputed = computed(() => props.isEdit)

    const current = ref(props.value)

    const labelCol = { span: 5 }
    const wrapperCol = { span: 17 }

    const formRef = ref()

    const handleSelectSongOk = info => {
      current.value = { ...toRaw(current.value), ...info }
    }

    const handleDelete = item => {
      const index = current.value.para_info.indexOf(item)
      const oldCurrent = ref({ ...toRaw(current.value) })
      const list = [...toRaw(oldCurrent.value.para_info)]
      list.splice(index, 1)
      oldCurrent.value.para_info = list
      current.value = oldCurrent.value
    }

    const handleAddParaInfo = () => {
      if (!current.value.para_info) {
        current.value.para_info = []
      }
      current.value.para_info.push({})
    }

    const handleCancel = () => {
      emit('cancel')
    }

    const handleOk = () => {
      const info = ref(toRaw(current.value))
      if (!info.value.song_name) {
        message.error('请选择歌曲上传')
        throw new Error('请选择歌曲上传')
      }
      if (info.value.para_info && info.value.para_info.length > 0) {
        info.value.para_info.forEach(item => {
          if (!item.name) {
            message.error('分段名不可为空')
            throw new Error('分段名不可为空')
          }
          if (item.start_time === undefined || item.start_time === null) {
            message.error('分段起始点不可为空')
            throw new Error('分段起始点不可为空')
          }
          if (!item.color) {
            message.error('分段点颜色不可为为空')
            throw new Error('分段点颜色不可为为空')
          }
        })
      }
      if (isEditComputed.value) {
        emit('onEditOk', info.value)
      } else {
        emit('onOk', info.value)
      }
      handleCancel()
    }

    return {
      formRef,
      labelCol,
      wrapperCol,
      visibleComputed,
      isEditComputed,
      current,
      colorList: ColorList,
      handleSelectSongOk,
      handleCancel,
      handleOk,
      handleDelete,
      handleAddParaInfo
    }
  }
}
</script>

<style>
.delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.delete-button:hover {
  color: #777;
}
.delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
