<template>
  <div>
    <div v-for="(item, index) in listComputed" :key="index" class="card_body">
      <a-descriptions :title="`配置${index + 1}`" bordered :column="6">
        <template #extra>
          <a-space v-if="formIsEditComputed">
            <a @click="() => handleRemoveItem(item)">移除</a>
            <a @click="() => handleEditItem(item, index)">编辑</a>
          </a-space>
        </template>
        <a-descriptions-item label="歌曲名" :span="3">
          {{ item.song_name }}
        </a-descriptions-item>
        <a-descriptions-item label="歌曲MD5" :span="3">
          {{ item.mp3_file_md5 }}
        </a-descriptions-item>
        <a-descriptions-item label="歌曲文件地址" :span="6">
          <a-tooltip
            v-if="item.mp3_file_url.length > 90"
            :title="item.mp3_file_url"
            >{{ item.mp3_file_url.slice(0, 90) }}...</a-tooltip
          >
          <span v-else>{{ mp3_file_url }}</span>
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions
        v-for="(pItem, pIndex) in item.para_info"
        :key="pIndex"
        bordered
        :column="6"
      >
        <a-descriptions-item :label="`分段名${pIndex + 1}`" :span="2">
          {{ pItem.name }}
        </a-descriptions-item>
        <a-descriptions-item label="起始点" :span="2">
          {{ pItem.start_time }}秒
        </a-descriptions-item>
        <a-descriptions-item label="颜色" :span="2">
          {{ ColorMap[pItem.color] }}
        </a-descriptions-item>
      </a-descriptions>
    </div>
    <a-button class="add-btn" @click="handleAddConfig" v-if="formIsEditComputed">
      <PlusOutlined />新增
    </a-button>
    <song-modal
      v-if="visible"
      :visible="visible"
      :isEdit="isEdit"
      :value="current"
      @cancel="handleModalCancel"
      @onOk="handleModalOk"
      @onEditOk="handleModalEditOk"
    ></song-modal>
  </div>
</template>

<script>
import { PlusOutlined } from '@ant-design/icons-vue'
import { computed, ref, toRaw } from 'vue'
import SongModal from './SongModal.vue'
import { ColorMap } from './config'

export default {
  emits: ['updateList'],
  components: {
    PlusOutlined,
    SongModal
  },
  props: {
    list: {
      type: Array,
      default: () => { return [] }
    },
    formIsEdit: {
      type: Boolean,
      default: () => { return false }
    }
  },
  setup (props, { emit }) {
    // 列表数据
    const listComputed = computed(() => props.list)
    const formIsEditComputed = computed(() => props.formIsEdit)

    const current = ref({})
    const visible = ref(false)
    const isEdit = ref(false)

    const labelCol = { span: 5 }
    const wrapperCol = { span: 17 }
    const currentIndex = ref(-1)

    const handleRemoveItem = item => {
      const list = ref(toRaw(listComputed.value))
      const index = list.value.indexOf(item)
      if (index !== -1) {
        list.value.splice(index, 1)
      }
      emit('updateList', list)
    }

    const handleEditItem = (item, index) => {
      visible.value = true
      current.value = item
      currentIndex.value = index
      isEdit.value = true
    }

    const handleAddConfig = () => {
      isEdit.value = false
      current.value = {}
      visible.value = true
      currentIndex.value = -1
    }

    const handleModalCancel = () => {
      current.value = {}
      visible.value = false
      currentIndex.value = -1
    }

    const handleModalOk = info => {
      const list = ref(toRaw(listComputed.value))
      list.value.push(info)
      emit('updateList', list.value)
    }

    const handleModalEditOk = info => {
      const list = ref(toRaw(listComputed.value))
      list.value[currentIndex.value] = info
      emit('updateList', list.value)
    }

    return {
      current,
      visible,
      isEdit,
      labelCol,
      wrapperCol,
      formIsEditComputed,
      listComputed,
      ColorMap,
      handleAddConfig,
      handleRemoveItem,
      handleModalCancel,
      handleModalOk,
      handleEditItem,
      handleModalEditOk
    }
  }
}
</script>

<style>
.add-btn {
  width: 100%;
}

.card_body {
  margin-bottom: 10px;
}
</style>
