import SparkMD5 from 'spark-md5'
/**
 * 获取文件 MD5
 * @param {*} file 需要获取 MD5 的文件
 * @returns 携带 MD5 值的文件
 */
export const getFileMd5 = (file) => {
  const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
  const chunkSize = 2097152 // Read in chunks of 2MB
  const chunks = Math.ceil(file.size / chunkSize)
  let currentChunk = 0
  const spark = new SparkMD5.ArrayBuffer()

  const reader = new FileReader()

  function loadNext () {
    const start = currentChunk * chunkSize
    const end = start + chunkSize >= file.size ? file.size : start + chunkSize
    reader.readAsArrayBuffer(blobSlice.call(file, start, end))
  }

  return new Promise((resolve) => {
    reader.onload = (e) => {
      spark.append(e.target.result) // Append array buffer
      currentChunk += 1
      if (currentChunk < chunks) {
        loadNext()
      } else {
        file.md5 = spark.end()
        resolve(file.md5)
      }
    }
    loadNext()
  })
}
