<template>
  <a-row>
    <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
      <a-form
        ref="formRef"
        :model="formData"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <a-form-item
          name="name"
          label="歌曲名称"
          :rules="[{ required: true, message: '请输入歌曲名称' }]"
        >
          <a-input v-model:value="formData.name" />
        </a-form-item>
        <a-form-item
          name="song_code"
          label="歌曲识别码"
          :rules="[{ required: true, validator: codeLengthValidate }]"
          v-if="route.params.type === '1'"
        >
          <a-input v-model:value="formData.song_code" :disabled="isEdit">
            <template #suffix>
              <a-tooltip
                title="存在相同的歌曲识别码"
                v-if="hasSameCode"
                default-visible
              >
                <info-circle-outlined style="color: rgb(233, 103, 33)" />
              </a-tooltip>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item
          name="py"
          label="歌曲简拼"
          :rules="[{ required: true, message: '请输入歌曲简拼' }]"
        >
          <a-input v-model:value="formData.py" />
        </a-form-item>
        <a-form-item label="标签">
          <a-select
            style="width: 95%"
            v-model:value="tagList"
            :options="tagOpt"
            mode="multiple"
            @search="handleSerach"
          >
          </a-select>
          <SearchOutlined class="serach_icon" />
        </a-form-item>
        <a-form-item name="book_info" label="教材选择">
          <select-book
            :value="bookInfo"
            :bookName="bookName"
            :sectionName="sectionName"
            @onBookSelcetOk="handleSelectBook"
          ></select-book>
        </a-form-item>
        <a-form-item label="教材名称/章节">
          <a-input :disabled="true" v-model:value="bookNameInput"></a-input>
        </a-form-item>
        <a-form-item label="歌曲图片">
          <upload-image
            :form="formData"
            @onChange="handleUploadFormChange"
          ></upload-image>
        </a-form-item>
        <a-form-item label="MP3配置">
          <upload-song-config
            @updateList="handleUpdateList"
            :formIsEdit="true"
            :list="formData.mp3_file_config"
          />
        </a-form-item>
      </a-form>
    </a-col>
    <a-col :offset="11" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
      <a-button type="primary" @click="onSubmit">保存</a-button>
    </a-col>
  </a-row>
</template>

<script>
import { useRoute } from 'vue-router'
import UploadImage from './UploadImage.vue'
import { FormActionType } from './config'
import { ref, computed, toRaw, onBeforeMount, watch } from 'vue'
import SelectBook from './SelectBook.vue'
import UploadSongConfig from './UploadSongConfig.vue'
import { debounce } from '@/utils/common'
import * as service from '@/services/songs'
import { SearchOutlined, InfoCircleOutlined } from '@ant-design/icons-vue'

export default {
  emits: ['onSubmit'],
  components: {
    UploadImage,
    SelectBook,
    UploadSongConfig,
    SearchOutlined,
    InfoCircleOutlined
  },
  props: {
    actionType: {
      type: String,
      default: () => { return FormActionType.CREATE }
    },
    // 如果是编辑，那么current代表的是当前选中的歌曲的信息
    current: {
      type: Object,
      default: () => { return {} }
    }
  },
  setup (props, { emit }) {
    const route = useRoute()
    // 是否存在相同code
    const hasSameCode = ref(false)
    // 表单ref
    const formRef = ref()
    // 传入的默认值
    const currentValue = computed(() => (props.current))
    // 是否是编辑模式
    const isEdit = computed(() => [FormActionType.EDIT].includes(props.actionType))

    const labelCol = { span: 4 }
    const wrapperCol = { span: 17 }

    // 教材名
    const bookName = ref(currentValue.value.bookName || '')
    // 章节名
    const sectionName = ref(currentValue.value.sectionName || '')
    // 教材/章节名
    const bookNameInput = computed(() => `${bookName.value ? bookName.value + '/' : ''}${sectionName.value}`)

    // 教材信息
    const bookInfo = ref({
      book_id: currentValue.value?.book_id,
      book_section_id: currentValue.value?.book_section_id
    })

    // 表单数据
    const formData = ref({
      name: '',
      song_code: '',
      py: '',
      picture_url: [],
      mp3_file_config: [],
      book_id: undefined,
      book_section_id: undefined,
      ...toRaw(currentValue.value)
    })

    const tagList = ref(currentValue.value?.tags || [])
    const tagOpt = ref([])

    const getTags = async name => {
      const getTagsInfo = route.params.type === '1' ? service.getOfficialTag : service.getPersonalTag
      const data = await getTagsInfo({ keyword: name, per_page: 10, page: 1 })
      if (!data.items || data.items.length < 1) {
        data.items = [{ tag: name }]
      }
      return data
    }

    const handleSerach = debounce(async value => {
      const data = await getTags(value)
      tagOpt.value = data.items.map(item => { return { label: item.tag, value: item.tag } })
    })

    onBeforeMount(async () => {
      await handleSerach('')
    })

    // 歌曲识别码自定义验证规则
    const codeLengthValidate = (rule, value) => {
      if (!value) {
        Promise.reject(new Error('请输入歌曲识别码'))
      }
      if (value.length !== 7 && route.params.type === '1') {
        return Promise.reject(new Error('请输入7位歌曲识别码'))
      }
      return Promise.resolve()
    }

    // 更新MP3配置列表信息
    const handleUpdateList = info => {
      const oldData = ref(toRaw(formData.value))
      oldData.value.mp3_file_config = info
      formData.value = oldData.value
    }

    // 表单修改
    const handleUploadFormChange = info => {
      formData.value = info
    }

    const handleSelectBook = (params) => {
      formData.value = {
        ...toRaw(formData.value),
        book_id: params.book_id,
        book_section_id: params.book_section_id
      }
      bookName.value = params.bookName
      sectionName.value = params.sectionName
    }

    const onSubmit = () => {
      formRef.value.validate().then(() => {
        const params = formData.value
        if (params.book_id === null || params.book_id === undefined || params.book_section_id === null || params.book_section_id === undefined) {
          params.book_id = 0
          params.book_section_id = 0
        }
        emit('onSubmit', params, tagList.value)
      })
    }

    const checkSameCode = debounce(async () => {
      const { exist } = await service.checkEnjoySongCode({ song_code: formData.value.song_code })
      hasSameCode.value = exist
    })

    watch(() => formData.value.song_code, (v) => {
      if (v.length === 7) {
        checkSameCode()
      } else {
        hasSameCode.value = false
      }
    })

    return {
      route,
      formRef,
      formData,
      isEdit,
      bookName,
      bookInfo,
      sectionName,
      labelCol,
      wrapperCol,
      tagList,
      tagOpt,
      hasSameCode,
      bookNameInput,
      handleSerach,
      codeLengthValidate,
      handleUpdateList,
      handleUploadFormChange,
      handleSelectBook,
      onSubmit
    }
  }
}
</script>

<style>
.serach_icon {
  margin-left: 10px;
  color: #f5f5f5;
}
</style>
