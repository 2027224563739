import store from '@/store'
import { SigleConfigFactory } from './sigleConfigFactory'
import OSS from 'ali-oss'
import moment from 'moment'
import { getFileMd5 } from './md5Utils'
import { saveAs } from 'file-saver'

const initOssConfig = async () => {
  const ins = SigleConfigFactory.getInstance()
  try {
    await ins.getOssUploadConfig()
    return store.state.ossConfig
  } catch (error) {
    console.log(error)
  }
  return null
}

// 获取oss配置
const getOssConfig = () => {
  let ossConfig:any = store.state.ossConfig
  if (!ossConfig || ossConfig === null) {
    // 没有配置，获取配置
    ossConfig = initOssConfig()
  } else if (ossConfig.refresh_time < new Date().getTime()) {
    ossConfig = initOssConfig()
  }
  return ossConfig
}

// 获取上传路径
const uploadPath = (path: string, file: any, rename = '') => {
  const storeName = rename ?? file.name.split('.')[0]
  return `${path}/${moment().format('YYYYMMDD')}/${storeName}-${
    file.uid
  }.${file.name.substring(file.name.lastIndexOf('.') + 1) || file.type.split('/')[1]}`
}

// 对外暴露的上传方法
export const uploadFileToOss = async (file: any, needMd5 = false, onProgressChange: any = undefined, rename = '', cache = true) => {
  // 获取oss配置
  const ossConfig = await getOssConfig()
  // 创建oss客户端
  const ossClient = new OSS({
    accessKeyId: ossConfig.access_key_id,
    accessKeySecret: ossConfig.access_key_secret,
    region: ossConfig.region,
    bucket: ossConfig.bucket,
    stsToken: ossConfig.sts_token
  })
  // 保存文件名
  const storeAs = uploadPath(ossConfig.base_path, file, rename)
  const headers = {}
  if (!cache) headers['Cache-Control'] = 'no-cache'

  return new Promise((resolve, reject) => {
    // 读取文件
    const reader = new FileReader()
    reader.readAsDataURL(file)
    // 读取后上传
    reader.onloadend = async () => {
      ossClient.multipartUpload(storeAs, file, {
        progress: (precentage) => {
          if (onProgressChange) {
            onProgressChange(Math.round(precentage * 100))
          }
        },
        partSize: 1024 * 1024,
        timeout: 60000,
        headers: headers
      }).then(async (data:any) => {
        const url = ossConfig.download_domain + new URL(data.res.requestUrls).pathname
        let md5 = ''
        if (needMd5) {
          md5 = await getFileMd5(file)
        }
        resolve({
          url,
          md5,
          name: file.name,
          size: file.size,
          uid: file.uid
        })
      }).catch(error => {
        reject(error)
      })
    }
  })
}

export const downLoadFromOss = async (url: string, name: string) => {
  saveAs(url, name)
}
